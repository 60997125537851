import { acceptHMRUpdate, defineStore } from "pinia";

import { GenericResponse, USER_ROLEID_ERROR_MESSAGE } from "~/utils/constants";

export type User = {
  avatar?: string;
  email: string | undefined;
  firstName?: string;
  id?: number;
  isVerified: boolean;
  lastName?: string;
  role: string;
  subscriptionId?: string;
};

export type UserData = {
  userState: {
    avatar?: string;
    email: string | undefined;
    firstName?: string;
    id?: number | undefined;
    isVerified: boolean;
    lastName?: string;
    role: string;
    subscriptionId?: string;
  } | null;
};

export type UpdateUserData = {
  avatar?: string;
  email: string | undefined;
  firstName?: string;
  isVerified?: boolean;
  lastName?: string;
  role?: string;
  subscriptionId?: string;
};

export const useUserStateStore = defineStore("UserStateStore", {
  state: (): UserData => {
    return {
      userState: null as User | null,
    };
  },
  actions: {
    /**
     * Gets the role id for a given role from the database.
     * @param role - the role to get the id for
     * @returns UserRoleReponse { id: number }
     */
    async getRoleId(role: string): Promise<number | undefined> {
      const { data, error } = await useFetch(
        `${API_ENDPOINT_USER_ROLE_ID}?userRole=${role}`
      );

      if (error.value) {
        throw new OperationalError(USER_ROLEID_ERROR_MESSAGE, {
          cause: `Error getting role id for role ${role} from the database. ${error}`,
        });
      }

      return data.value?.id;
    },
    /**
     * Sets the subscription ID for the user state.
     * @param subscriptionId The ID of the subscription.
     * @returns A promise that resolves when the operation is complete.
     */
    async setSubscriptionId(subscriptionId: string) {
      this.$patch({ userState: { subscriptionId } });
    },
    /**
     * Creates a new user in the database and stores the user in the Pinia store.
     * @param user - the user object used to create the user in the database and store in the session.
     */
    async createUser(user: User) {
      const roleId = await this.getRoleId("basic");

      const { data, error } = await useFetch(API_ENDPOINT_USER_CREATE, {
        method: "POST",
        body: JSON.stringify({
          avatar: user.avatar,
          email: user.email,
          firstName: user.firstName,
          isVerified: user.isVerified,
          lastLogin: new Date(),
          lastName: user.lastName,
          roleId,
        }),
      });

      if (error.value) {
        throw new OperationalError(USER_CREATE_ERROR_MESSAGE, {
          cause: `Error storing user details in the database. ${error}`,
        });
      }

      this.$patch({ userState: { ...(data.value as User) } });
    },
    /**
     * Attempts to load a user from the database. If successful, stores the user in the Pinia store.
     * @param email - the email address of the user to load from the database
     * @returns void
     */
    async loadUser(email: string): Promise<boolean | undefined> {
      const { data, error } = await useFetch(
        `${API_ENDPOINT_USER_DETAILS}?email=${email}`
      );

      if (error.value) {
        throw new OperationalError(USER_LOAD_ERROR_MESSAGE, {
          cause: `Error loading user details from the database. ${error}`,
        });
      }

      if (data.value) {
        this.$patch({ userState: { ...(data.value as User) } });
        return true;
      }

      return false;
    },
    /**
     * Updates a user in the database and stores the updated user in the Pinia store.
     * @param user - the user object used to update the user in the database.
     * @returns GenericResponse { data?: any, message: string }
     */
    async updateUser(user: UpdateUserData): Promise<GenericResponse> {
      const { data, error } = await useFetch(API_ENDPOINT_USER_UPDATE, {
        method: "POST",
        body: JSON.stringify(user),
      });

      if (error.value) {
        throw new OperationalError(USER_UPDATE_ERROR_MESSAGE, {
          cause: `Error updating user details in the database. ${error}`,
        });
      }

      this.$patch({ userState: { ...(data.value as User) } });

      return {
        data: data.value,
        message: USER_UPDATE_SUCCESS,
      };
    },
  },
  persist: {
    storage: persistedState.localStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStateStore, import.meta.hot));
}
